/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyBpT4Vf7WanWVhb4q9y71U6mRfLi8Wjgnw",
  "appId": "1:181063462273:web:47dc5368a4e4a0e2b64c80",
  "authDomain": "schooldog-i-butts-ga.firebaseapp.com",
  "measurementId": "G-88FJ4GLVV9",
  "messagingSenderId": "181063462273",
  "project": "schooldog-i-butts-ga",
  "projectId": "schooldog-i-butts-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-butts-ga.appspot.com"
}
